/* global jsVars */
import ajax from '../helpers/ajax';

const searchTerm = document.getElementById('home-search-exhibitor');
const searchContainer = document.querySelector('.tlw__exhdir__result');
const searchResult = document.getElementById('content-search-result');
const searchLoading = document.getElementById('content-search-loading');
const searchClose = document.getElementById('home-search-exhibitor-close');

window.addEventListener('load', function () {
	if (!searchTerm) {
		return;
	}

	function templateResult(item, child) {
		return `<ul>
		<li>${item.text}</li>
		${
			child.length > 0 &&
			`
			<ul>${child
				.map(
					(l) => `
				${
					item.text === 'PRODUCTOS / SERVICIOS'
						? `
				<li><a href="${
					jsVars.baseUrl +
					'/directorio/?dir-id-cat-parent=' +
					l.parent_category_id +
					'&dir-id-cat=' +
					l.subcategory_id +
					'&dir-name-cat=' +
					l.text +
					'&dir-subcat-filter=' +
					l.subcategory_id
				}">${l.text}</a>
				`
						: item.text === 'PRODUCTOS / FICHAS TÉCNICAS'
						? `
					<li><a href="${jsVars.baseUrl + '/directorio/?dir-prod=' + l.text}">${l.text}</a>`
						: `
					<li><a href="${jsVars.baseUrl + l.id}">${l.text}</a>
				`
				}`,
				)
				.join('\n')}
			</ul>`
		}
		</ul>`;
	}

	searchTerm.addEventListener('keyup', (e) => {
		const value = e.target.value;

		searchResult.innerHTML = '';
		if (value.length > 2) {
			searchTerm.style.backgroundImage = 'none';
			searchLoading.classList.remove('hide');
			searchContainer.classList.add('show');
			searchClose.classList.remove('hide');
			ajax({
				url: jsVars.baseUrl + `/ajax-buscador-expositores/?term=${value}&_type=query&q=${value}`,
				method: 'GET',
				async: true,
				done: function (response) {
					// console.log(response);
					let contentResult = '';
					const data = response;

					if (response.length > 0) {
						data.forEach((item) => {
							const child = item.children;

							contentResult += templateResult(item, child);
						});
						searchResult.innerHTML = contentResult;
					} else {
						searchResult.innerHTML = 'No hay resultados que conincidan con tu busqueda';
					}
				},
				error: function (error) {
					// eslint-disable-next-line no-console
					console.info(error);
				},
				always: function () {
					searchLoading.classList.add('hide');
				},
			});
		} else {
			searchTerm.style.backgroundImage = `url(${
				jsVars.theme_root + '/assets/img/icon-search-blue.svg'
			})`;
			searchResult.innerHTML = '';
			searchLoading.classList.add('hide');
			searchContainer.classList.remove('show');
			searchClose.classList.add('hide');
		}
	});

	searchClose.addEventListener('click', () => {
		searchTerm.value = '';
		searchTerm.style.backgroundImage = `url(${
			jsVars.theme_root + '/assets/img/icon-search-blue.svg'
		})`;
		searchResult.innerHTML = '';
		searchLoading.classList.add('hide');
		searchContainer.classList.remove('show');
		searchClose.classList.add('hide');
	});
});
